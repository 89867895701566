import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecentSamples, useAdverse } from "../api";
import { useParamsContext } from "../contexts";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { CardA } from "../components/ui/Cards";
import { SequenceD } from "../components/ui/Sequence";
import { ColourCircleC } from "../components/ui/ColourCircle";
import { DateTime } from "luxon";
import { find } from "lodash";
import ReachbackModal from "../components/ui/ReachbackModal";

export default function RecentSamples() {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalFinding, setModalFinding] = useState("");
  const [modalScanType, setModalScanType] = useState("");

  const [dateRange, setDateRange] = useState({
    start: sevenDaysAgo,
    end: today,
  });
  const { data: adverse, isSuccess: adverseSuccess } = useAdverse();
  const { getColour } = useParamsContext();

  const handleDateRangeChange = (event) => {
    const selectedDateRange = parseInt(event.target.value, 10);
    let endDate = new Date(); // Current date
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - selectedDateRange);
    setDateRange({ start: startDate, end: endDate });
  };

  const {
    data: recentSamples,
    isLoading,
    isSuccess,
    isErrorT,
    isErrorB,
  } = useRecentSamples(dateRange);

  const calculateSelectedValue = () => {
    // Calculate the number of days in the date range
    //NOTE: When there is a time change in the date range, the calculation will be off by an hour,so we round it to the nearest whole number
    const daysDifference = Math.round(
      (dateRange.end - dateRange.start) / (1000 * 60 * 60 * 24)
    );

    // Find the corresponding option value
    switch (daysDifference) {
      case 7:
        return 7;
      case 14:
        return 14;
      case 30:
        return 30;
      case 60:
        return 60;
      case 90:
        return 90;
      default:
        return 0; // Default to 0 if it doesn't match any option
    }
  };

  const getAdverse = (categories, adverseList) => {
    const reactions = [];
    if (categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        reactions.push(
          find(adverseList, ["id", categories[i].categoryId]).label
        );
      }
      return reactions;
    }
    return reactions;
  };

  if (isLoading) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div style={{ marginTop: "7rem" }}>
          <div
            className='alert mx-3'
            role='alert'
            style={{ backgroundColor: "#7ECBF7", fontWeight: "bold" }}
          >
            Fetching samples ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (isErrorT || isErrorB) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div style={{ marginTop: "7rem" }}>
          <div className='alert alert-danger mx-3' role='alert'>
            Something went wrong, try refreshing the page
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Navbar signedIn={false} />
      <div className='mx-3' style={{ marginTop: "5rem" }}>
        {isSuccess && (
          <CardA title='Recent Samples'>
            <div>
              <select
                id='dateRangeSelect'
                value={calculateSelectedValue()}
                onChange={handleDateRangeChange}
                style={{ marginBottom: "1rem", marginLeft: "0.5rem" }}
              >
                <option value={7}>Past 7 days</option>
                <option value={14}>Past 14 days</option>
                <option value={30}>Past 30 days</option>
                <option value={60}>Past 60 days</option>
                <option value={90}>Past 90 days</option>
              </select>
            </div>
            <table className='table table-hover'>
              <thead>
                <tr className='text-center'>
                  <th scope='col'>Sample ID</th>
                  <th scope='col'>Date</th>
                  <th scope='col'>Time</th>
                  <th scope='col'>Presumed substance</th>
                  <th scope='col'>Colour</th>
                  <th scope='col'>Bulk scan</th>
                  <th scope='col'>Trace scan</th>
                  {/* <th scope="col">Noteworthy</th>
                  <th scope="col">Reachback</th> */}
                  <th scope='col'>Adverse reaction</th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                {recentSamples.sequences.map((sample) => (
                  <tr key={sample._id}>
                    <td className='text-center align-middle'>
                      {sample.sampleId}
                    </td>
                    <td className='text-center align-middle'>
                      {DateTime.fromSQL(
                        sample.processedDate.slice(
                          0,
                          sample.processedDate.indexOf("T")
                        ),
                        { zone: "utc" }
                      ).toFormat("DDD")}
                    </td>
                    <td className='text-center align-middle'>
                      {DateTime.fromSQL(
                        sample.processedDate.slice(
                          11,
                          sample.processedDate.length - 5
                        ),
                        { zone: "utc" }
                      )
                        .toLocal()
                        .toFormat("t")}
                    </td>
                    <td className='text-center align-middle'>
                      {sample.presumedSubstance.includes("Fentalog")
                        ? sample.presumedSubstance.split(" ")[0]
                        : sample.presumedSubstance.includes("Benzodiazepines")
                        ? sample.presumedSubstance.split(" ")[0]
                        : sample.presumedSubstance}
                    </td>
                    <td className='text-center align-middle'>
                      {<ColourCircleC data={getColour(sample.colourId)} />}
                    </td>
                    <td className='align-middle'>
                      {sample.tests.filter(
                        (item) =>
                          item.rb_findings?.length > 0 &&
                          item.scanType.toLowerCase() === "bulk-burst"
                      ).length > 0 // if there are any bulk-burst tests and rb_findings exist, display them
                        ? sample.tests
                            .filter(
                              (item) =>
                                item.rb_findings?.length > 0 &&
                                item.scanType.toLowerCase() === "bulk-burst"
                            )
                            .map((item, index) => (
                              <div key={index} className='col-lg-12 mb-2'>
                                <SequenceD
                                  key={index}
                                  sequenceId={item.sequenceId || item.scanId}
                                  findings={
                                    item.rb_findings?.map(
                                      (finding) => finding.substance
                                      // )
                                    ) || ["Inconclusive"]
                                  }
                                />
                              </div>
                            ))
                        : sample.tests.filter(
                            (item) =>
                              item.findings.length > 0 &&
                              item.scanType.toLowerCase() === "bulk-burst"
                          ).length > 0 && //else if there are any bulk-burst tests and findings exist, display them
                          sample.tests
                            .filter(
                              (item) =>
                                item.findings.length > 0 &&
                                item.scanType.toLowerCase() === "bulk-burst"
                            )
                            .map((item, index) => (
                              <div key={index} className='col-lg-12 mb-2'>
                                <SequenceD
                                  key={index}
                                  sequenceId={item.sequenceId || item.scanId}
                                  reachback={
                                    sample.isErrorB && sample.reachback
                                      ? true
                                      : false
                                  }
                                  findings={
                                    item.findings.map(
                                      (finding) => finding.substance
                                      // )
                                    ) || ["Inconclusive"]
                                  }
                                  setModalVisible={(finding) => {
                                    setModalVisible(true);
                                    setModalFinding(finding);
                                    setModalScanType("Bulk Scan");
                                  }}
                                />
                              </div>
                            ))}
                    </td>

                    <td className='align-middle'>
                      {sample.tests
                        .filter((item) => item.rb_findings?.length > 0)
                        .filter(
                          (item) =>
                            item.scanType.toLowerCase() === "trace-burst"
                        ).length > 0 ? ( //if there are any trace-burst tests and rb_findings exist, display them
                        sample.tests
                          .filter((item) => item.rb_findings?.length > 0)
                          .filter(
                            (item) =>
                              item.scanType.toLowerCase() === "trace-burst"
                          )
                          .map((item, index) => (
                            <SequenceD
                              key={index}
                              sequenceId={item.sequenceId || item.scanId}
                              findings={
                                item.rb_findings?.map(
                                  (finding) => finding.substance
                                )
                                // ) || ["Inconclusive"]
                              }
                            />
                          ))
                      ) : sample.tests
                          .filter((item) => item.findings.length > 0)
                          .filter(
                            (item) =>
                              item.scanType.toLowerCase() === "trace-burst"
                          ).length > 0 ? ( //else if there are any trace-burst tests and findings exist, display them
                        sample.tests
                          .filter((item) => item.findings.length > 0)
                          .filter(
                            (item) =>
                              item.scanType.toLowerCase() === "trace-burst"
                          )
                          .map((item, index) => (
                            <SequenceD
                              key={index}
                              sequenceId={item.sequenceId || item.scanId}
                              reachback={
                                sample.isErrorT && sample.reachback
                                  ? true
                                  : false
                              }
                              findings={
                                item.findings.map(
                                  (finding) => finding.substance
                                )
                                // ) || ["Inconclusive"]
                              }
                              setModalVisible={(finding) => {
                                setModalVisible(true);
                                setModalFinding(finding);
                                setModalScanType("Trace Scan");
                              }}
                            />
                          ))
                      ) : (
                        <div className='col-6'>
                          <p className='card-text'></p>
                        </div>
                      )}
                    </td>
                    {/* <td className="text-center align-middle">
                      {sample.noteworthy
                        ? sample.noteworthy.length === 0
                          ? "No"
                          : "Yes"
                        : "No"}
                    </td> */}

                    {/* this needs to accept reachback values */}

                    {/* <td className="text-center align-middle">
                      {sample.reachback === true
                        ? "Yes"
                        : sample.reachback === false
                        ? "No"
                        : "N/A"}
                    </td> */}

                    <td>
                      {sample
                        ? adverseSuccess
                          ? getAdverse(sample.categories, adverse).map(
                              (item, index) => {
                                return <li key={index}>{item}</li>;
                              }
                            )
                          : "Loading..."
                        : "N/A"}
                    </td>
                    <td className='text-center align-middle'>
                      <Link className='rounded' to={`/sample/${sample._id}`}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          fill='#1ca3f0'
                          className='bi bi-pencil-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z' />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardA>
        )}
        <ReachbackModal
          showModal={modalVisible}
          handleClose={() => setModalVisible(false)}
          finding={modalFinding}
          scanType={modalScanType}
        ></ReachbackModal>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "../styles/Body.css";
import { CardA } from "./ui/Cards";
import NumberVsCard from "./stats/NumberVsCard";
import { useSampleStats } from "../api";

const Body = () => {
  // List of prescription drugs to exclude from the presumption score calculation
  const prescriptions = [
    "Adderall®",
    "Ativan®",
    "Dilaudid®",
    "Klonopin®",
    "OxyContin®",
    "Ritalin®",
    "Valium®",
    "Vyvanse®",
    "Xanax®",
  ];

  // Initialize date variables
  const [now] = useState(new Date());
  const [dateRange, setDateRange] = useState(7);
  const [pastStart, setPastStart] = useState(
    new Date(Date.now() - dateRange * 24 * 60 * 60 * 1000)
  );

  const handleDateRangeChange = (event) => {
    const selectedDateRange = parseInt(event.target.value, 10);
    setDateRange(selectedDateRange);
  };

  useEffect(() => {
    const newPastStart = new Date(Date.now() - dateRange * 24 * 60 * 60 * 1000);
    setPastStart(newPastStart);
  }, [dateRange]);

  // Call API functions
  const { data: pastPeriod, isFetching: isFetchingPastPeriod } = useSampleStats(
    pastStart,
    now
  );
  const { data: past2Periods, isFetching: isFetchingPast2Periods } =
    useSampleStats(
      new Date(pastStart.getTime() - (dateRange / 2) * 24 * 60 * 60 * 1000),
      pastStart
    );

  let pastPeriodPresumptionPercent,
    pastPeriodRiskPercent,
    pastPeriodAdversePercent;
  if (!isFetchingPastPeriod) {
    // Calculate the number of presumed=actual tests in the past period
    const pastPeriodPresumption = pastPeriod.filter((sample) =>
      sample.tests.some((test) =>
        test.findings.some(
          (finding) =>
            finding.substance
              .toLowerCase()
              .substring(0, 4)
              .includes(sample.presumedSubstance.toLowerCase().substring(0, 4)) //comparing substrings to account for naming conventions of fentalogs, benzodiazepines, etc.
        )
      )
    );

    // Calculate the number of noteworthy samples in the past period
    const pastPeriodRisk = pastPeriod.filter(
      (sample) => sample.noteworthy && sample.noteworthy[0] === "Yes"
    );

    // Calculate the number of adverse reactions in the past period
    const pastPeriodAdverse = pastPeriod.filter(
      (sample) => sample.categories.length !== 0
    );

    // Don't divide by 0 if there are no samples
    //any substance that is  "Other" or a prescription drug is not counted in the presumption score
    pastPeriodPresumptionPercent =
      pastPeriod
        .filter(
          (item) =>
            !item.presumedSubstance.includes("Other") ||
            prescriptions.some((drug) => drug === item.presumedSubstance)
        )
        .filter(
          (item) =>
            !prescriptions.some((drug) => drug === item.presumedSubstance)
        ).length > 0
        ? (pastPeriodPresumption.length /
            pastPeriod
              .filter(
                (item) =>
                  !item.presumedSubstance.includes("Other") ||
                  prescriptions.some((drug) => drug === item.presumedSubstance)
              )
              .filter(
                (item) =>
                  !prescriptions.some((drug) => drug === item.presumedSubstance)
              ).length) *
          100
        : 0;
    pastPeriodRiskPercent =
      pastPeriod.length > 0
        ? (pastPeriodRisk.length / pastPeriod.length) * 100
        : 0;
    pastPeriodAdversePercent =
      pastPeriod.length > 0
        ? (pastPeriodAdverse.length / pastPeriod.length) * 100
        : 0;
  }

  let past2PeriodsPresumptionPercent,
    past2PeriodsRiskPercent,
    past2PeriodsAdversePercent;
  if (!isFetchingPast2Periods) {
    // Same calculations as above
    const past2PeriodsPresumption = past2Periods.filter((sample) =>
      sample.tests.some((test) =>
        test.findings.some(
          (finding) =>
            finding.substance
              .toLowerCase()
              .substring(0, 4)
              .includes(sample.presumedSubstance.toLowerCase().substring(0, 4)) //comparing substrings to account for naming conventions of fentalogs, benzodiazepines, etc.
        )
      )
    );

    // NOTE: Noteworthy is not currently being used but may be reimplemented later
    // const past2PeriodsRisk = past2Periods.filter(
    //   (sample) => sample.noteworthy && sample.noteworthy[0] === "Yes"
    // );
    const past2PeriodsAdverse = past2Periods.filter(
      (sample) => sample.categories.length !== 0
    );

    //any substance that is "Other" or a prescription drug is not counted in the presumption score
    past2PeriodsPresumptionPercent =
      past2Periods
        .filter(
          (item) =>
            !item.presumedSubstance.includes("Other") ||
            prescriptions.some((drug) => drug === item.presumedSubstance)
        )
        .filter(
          (item) =>
            !prescriptions.some((drug) => drug === item.presumedSubstance)
        ).length > 0
        ? (past2PeriodsPresumption.length /
            past2Periods
              .filter(
                (item) =>
                  !item.presumedSubstance.includes("Other") ||
                  prescriptions.some((drug) => drug === item.presumedSubstance)
              )
              .filter(
                (item) =>
                  !prescriptions.some((drug) => drug === item.presumedSubstance)
              ).length) *
          100
        : 0;
    // past2PeriodsRiskPercent =
    //   past2Periods.length > 0
    //     ? (past2PeriodsRisk.length / past2Periods.length) * 100
    //     : 0;
    past2PeriodsAdversePercent =
      past2Periods.length > 0
        ? (past2PeriodsAdverse.length / past2Periods.length) * 100
        : 0;
  }

  return (
    <div className='px-5 mb-5' style={{ marginTop: "7rem" }}>
      <CardA icon='fas fa-chart-bar' title='Location Summary'>
        <div style={{ marginBottom: "1rem" }}>
          <select
            id='dateRangeSelect'
            value={dateRange}
            onChange={handleDateRangeChange}
          >
            <option value='7'>Past 7 days</option>
            <option value='14'>Past 14 days</option>
            <option value='30'>Past 30 days</option>
            <option value='60'>Past 60 days</option>
            <option value='90'>Past 90 days</option>
          </select>
        </div>
        {isFetchingPastPeriod || isFetchingPast2Periods ? (
          ""
        ) : (
          <div className='row'>
            <div className='col-md-4'>
              <CardA
                icon='fas fa-flask'
                title='Samples Tested'
                help={`Total number of samples in the past ${dateRange} days`}
              >
                <NumberVsCard
                  value={pastPeriod.length}
                  vsValue={past2Periods.length}
                  vsText='vs previous'
                />
              </CardA>
            </div>
            <div className='col-md-4'>
              <CardA
                icon='fas fa-eye'
                title='Presumption Score'
                help={`Percentage of samples guessed correctly in the past ${dateRange} days`}
              >
                <NumberVsCard
                  unit='%'
                  value={pastPeriodPresumptionPercent.toFixed(2)}
                  vsValue={past2PeriodsPresumptionPercent}
                  vsText='vs previous'
                />
              </CardA>
            </div>

            {/* NOTE: Noteworthy is not currently being used, but we might re-implement it in the future */}
            {/* <div className='col-md-3'>
              <CardA
                icon="fas fa-exclamation-triangle"
                title="Risk Score"
                help={`Number and percentage of samples with noteworthy findings in the past ${dateRange} days`}
              >
                <NumberVsCard
                  unit="%"
                  value={pastPeriodRiskPercent.toFixed(2)}
                  vsValue={past2PeriodsRiskPercent}
                  vsText="vs previous"
                />
              </CardA>
            </div> */}
            <div className='col-md-4'>
              <CardA
                icon='fas fa-flag'
                title='Adverse Reaction Score'
                help={`Number and percentage of samples with adverse reactions in the past ${dateRange} days`}
              >
                <NumberVsCard
                  unit='%'
                  value={pastPeriodAdversePercent.toFixed(2)}
                  vsValue={past2PeriodsAdversePercent}
                  vsText='vs previous'
                />
              </CardA>
            </div>
          </div>
        )}
      </CardA>
    </div>
  );
};

export default Body;
